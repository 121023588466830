import React, { useEffect, useRef } from 'react';
import loadable from '@loadable/component';
import styled from '@emotion/styled';
import CopyIcon from '@sentry/static-shared/icons/copy.svg';
import { Plausible } from '@sentry/static-shared/utils/plausible.js';
import useCopyToClipboard from '@sentry/static-shared/utils/useCopyToClipboard.ts';
import {
  codeBackground,
  mdPink,
  white,
} from '@sentry/static-shared/utils/css/colors';
import { mqMin, screenReadersOnly } from '../utils/css';
import DeferrableHighlight from './highlight-deferred';


function Highlight({ language, children, showCopyButton = true, fileName, className }) {
  const [value, copy] = useCopyToClipboard()
  const tooltipMessage = value ? 'Copied!' : 'Click to Copy';
  const [toggleIcon] = [!(!!value)];
  const [toggleLabel] = [!!value];
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef?.current && typeof Prism !== 'undefined') {
      Prism?.highlightAllUnder(containerRef.current);
    }
  }, [children, language, containerRef?.current]);

  const handleCopyEvent = () => {
    copy(children);
    Plausible('copy sentry code');
  }

  const divProps = {
    role: showCopyButton ? 'button' : 'code',
    tabIndex: 0,
    onKeyDown: showCopyButton ? handleCopyEvent : null,
    onClick: showCopyButton ? handleCopyEvent : null,
    title: showCopyButton ? tooltipMessage : null,
    ref: containerRef
  };

  const usesFileNameLayout = !!fileName;
  const preTagClassName = usesFileNameLayout
    ? `language-${language} filename-layout`
    : `language-${language}`;

  return (
    <div {...divProps } className={className}>
      {usesFileNameLayout ? (
        <CodeActions className={'code-actions'} hasFileName={!!fileName}>
          <code className={'file-name'}>{fileName}</code>
          {showCopyButton && (
            <>
              <ScreenReaderSpan>{'Click to Copy'}</ScreenReaderSpan>
              <StyledIcon visible={toggleIcon} StyledIcon as={CopyIcon}/>
            </>
          )}
          <LabelWrapper visible={toggleLabel}>
            <StyledLabel>{tooltipMessage}</StyledLabel>
          </LabelWrapper>
        </CodeActions>
      ) : (
        showCopyButton && (<>
          <ScreenReaderSpan>{'Click to Copy'}</ScreenReaderSpan>
          <ButtonOverlay className={'button-overlay'}>
            <StyledLabel visible={toggleLabel}>{tooltipMessage}</StyledLabel>
            <div>
              <StyledIcon visible={toggleIcon} StyledIcon as={CopyIcon}/>
            </div>
          </ButtonOverlay>
        </>)
      )}
      <StyledPre className={preTagClassName}>
        <Code className={`language-${language}`} showCopyButton={showCopyButton}>
          <DeferrableHighlight fallback={children}>
            <code className={`language-${language}`}>
              {children}
            </code>
          </DeferrableHighlight>
        </Code>
      </StyledPre>
    </div>
  );
}

const ScreenReaderSpan = styled.span`
  ${screenReadersOnly}
`;

const LabelWrapper = styled.div`
  display: ${props => props.visible ? 'flex': 'none'};
  label {
    position: relative;
    float: none;
    box-shadow: none;
    display: flex;
    top: 0;
    left: 0;
  }
`;

const ButtonOverlay = styled.div`
  float: right;
  position: relative;
  right: 2rem;
  top: 0.75rem;
  cursor: pointer;
  z-index: 10;
`;

const CodeActions = styled.div`
  background: ${codeBackground};
  display: grid;

  grid-template-columns: max-content max-content;
  grid-gap: 1rem;
  align-items: center;

  margin-left: -1rem;
  margin-right: -1rem;

  padding: .5rem .75rem .5rem .75rem;
  justify-content: space-between;
  border-bottom: 1px #5B5765 solid;
  border-radius: 0.5rem 0.5rem 0 0;

  code, label {
    color: ${white};
    font-size: .75rem;
  }

  svg {
    box-shadow: none;
    align-self: center;
    position: relative;
  }
`;

const StyledPre = styled.pre`
  &.filename-layout {
    border-radius: 0 0 0.5rem 0.5rem;
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  z-index: 10;
  float: right;
  top: 2px;
  left: -2rem;
  align-self: flex-start;
  color: ${white};
  background: ${codeBackground};
  display: ${props => props.visible ? 'flex': 'none'};
  box-shadow: 0 0 7px 4px #160f24, 0 0 8px 3px transparent;
`;

const StyledIcon = styled.svg`
  position: absolute;
  z-index: 10;
  height: 20px;
  width: 20px;
  fill: white;
  align-self: flex-end;
  display: ${props => props.visible ? 'flex': 'none'};
  background: #160f24;
  box-shadow: 0 4px 4px 9px rgba(22, 15, 36, 0.9);
`;

const Code = styled.div`
  position: relative;
  color: inherit;
  transition: box-shadow 0.3s;
  will-change: box-shadow;
  outline: 0;
  display: flex;
  flex-direction: column;
  z-index: 9;
  
  &:hover,
  &:focus {
    cursor: ${props => props.showCopyButton ? 'pointer' : 'default'};
  }
  
  &:hover ${StyledIcon},
  &:focus ${StyledIcon} {
    fill: ${mdPink};
    cursor: ${props => props.showCopyButton ? 'pointer' : 'default'};
  }
`;


export default Highlight;