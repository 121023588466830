import React from "react";
import styled from '@emotion/styled';

import { mqMin } from '@sentry/static-shared/utils/css';
import {
  richBlack,
  blurple,
} from '@sentry/static-shared/utils/css/colors';
import PostDate from '@sentry/static-shared/components/PostDate';
import Link from '@sentry/static-shared/components/link';
import SocialShareWidget from "./SocialShareWidget";
import PostSidebarSignUp from './PostSidebarSignUp';
import PostSidebarBlogcast from './PostSidebarBlogcast';


const PostSidebar = ({ categories, publishDate, siteUrl, title, twitterHandle, url, blogcast }) => {
  const socialShareData = {
    siteUrl: siteUrl,
    title: title,
    twitterHandle: twitterHandle,
    url: url
  };

  return (
    <StyledPostSidebar lg={3}>
      <StyledSidebarSection>
        <StyledSidebarSectionHeader>Published</StyledSidebarSectionHeader>
        <StyledPostDate date={publishDate}/>
      </StyledSidebarSection>
      {
        blogcast && (
          <StyledSidebarSection>
            <PostSidebarBlogcast {...blogcast } />
          </StyledSidebarSection>
        )
      }
      <StyledSidebarSection>
        <PostSidebarSignUp />
      </StyledSidebarSection>
      <StyledSidebarSection>
        <SocialShareWidget shareData={socialShareData} />
      </StyledSidebarSection>
      {/* <StyledSidebarSection>
        <StyledSidebarSectionHeader>Category</StyledSidebarSectionHeader>
        <StyledSidebarLink to='/'>Company Blog</StyledSidebarLink>
      </StyledSidebarSection> */}
      {categories.length > 0 && <StyledSidebarSection>
        <StyledSidebarSectionHeader>Topics</StyledSidebarSectionHeader>
        {categories.map((category) => (
          <StyledSidebarLink to={`/${category.slug}/`} className="plausible-event-name=Blog+Click+Category+CTA">
            {category.title}
          </StyledSidebarLink>
        ))}
      </StyledSidebarSection>}
    </StyledPostSidebar>
  )
}

export default PostSidebar;

const StyledPostSidebar = styled.div`
  color: ${richBlack};
  padding: 1rem 1.75rem 1.5rem;

  ${mqMin('md')} {
    padding: 0;
  }
`;

const StyledSidebarSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
`;

const StyledSidebarSectionHeader = styled.p`
  font-size: 0.875rem;
  text-transform: uppercase;
`;

const StyledSidebarLink = styled(Link)`
  color: ${blurple};
  line-height: 150%;
  margin-bottom: 0.5rem;
`;

const StyledPostDate = styled(PostDate)`
  color: ${richBlack};
  display: inline-block;
  font-size: 1rem;
  height: 1rem;
  line-height: normal;
`;