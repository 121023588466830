import React from 'react';
import VimeoEmbed from '@sentry/static-shared/components/VimeoEmbed';
import styled from '@emotion/styled';
import { mqMin } from '@sentry/static-shared/utils/css';

const PostSidebarBlogcast = ({ vimeoID, thumbnail, aspectRatio, description }) => (
  <Container>
    <Title>Blogcast</Title>
    <p>{description}</p>
    <VimeoEmbed {...{ id: vimeoID, thumbnail, aspectRatio }} />
  </Container>
);

const Container = styled.div`
  width: 100%;
  
  ${mqMin('md')}{
    width: 50%;
  }
  
  ${mqMin('lg')}{
    width: 100%;
  }
`;

const Title = styled.p`
  font-size: 0.875rem;
  text-transform: uppercase;
`;


export default PostSidebarBlogcast;