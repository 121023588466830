

/*
 *  This hook tracks progress thru the blog/www by first dividing the blog/www content into 10 sections
 *  and then flagging each of the 10 sections as a "progress node", the last node being the final always.
 *  We intersect and track each intersection of these nodes and calculate our progress
 *  down the page that way.
 */

import { Plausible } from '../utils/plausible';

const useTrackReaderDepth = ({ contentContainerSelector }) => {

  const isSSR = typeof window === 'undefined' || typeof document === 'undefined';
  const EVENT = 'Read Progress';

  const sendToPlausible = (progress) => Plausible(EVENT, { readProgress: progress });

  const trackReaderDepth = () => {
    if (isSSR === false) {
      const contentContainer = document.querySelector(contentContainerSelector);
      const intersectedProgressMarks = new Set();
      const milestones = [25, 50, 75, 100];
      const reachedMilestones = new Set();

      const progressObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !intersectedProgressMarks.has(entry.target)) {
            intersectedProgressMarks.add(entry.target);
            calculateProgress();
          }
        });
      }, {
        threshold: 0.1
      });

      const observeProgressMarks = () => {
        const progressMarks = contentContainer?.querySelectorAll('.progress-mark');
        if (progressMarks) {
          progressMarks.forEach(mark => progressObserver.observe(mark));
        }
      };

      const calculateProgress = () => {
        const progressMarks = contentContainer?.querySelectorAll('.progress-mark')?.length;
        if (progressMarks) {
          const progressCount = intersectedProgressMarks.size;
          const progress = (progressCount / progressMarks) * 100;

          milestones.forEach((milestone) => {
            if (progress >= milestone && !reachedMilestones.has(milestone)) {
              reachedMilestones.add(milestone);
              sendToPlausible(milestone);
            }
          });
        }
      };

      // Observe progress marks after initial render
      observeProgressMarks();
      calculateProgress();

      window.addEventListener('scroll', calculateProgress);

      return () => {
        window.removeEventListener('scroll', calculateProgress);
        progressObserver.disconnect();
      };
    }
  };

  const addReaderDepthMarkersBlog = () => {
    if (isSSR === false) {
      const contentContainer = document?.querySelector(contentContainerSelector);
      if (contentContainer) {
        const children = Array.from(contentContainer.children);
        const interval = Math.ceil(children.length / 4); // Divide by 4 for 25% interval marks
        children.forEach((child, index) => {
          if (index % interval === 0 || index === children.length - 1) {
            child.classList.add('progress-mark');
          }
        });
      }
    }
  };

  const addReaderDepthMarkersWww = () => {
    if (isSSR === false) {
      const styledSiteContainer = document
        ?.querySelector(contentContainerSelector)
        ?.children[0];

      if (styledSiteContainer) {
        const header = styledSiteContainer.querySelector('header');
        const footer = styledSiteContainer.querySelector('footer');
        if (header && footer) {
          const children = [];
          let sibling = header.nextElementSibling;

          while (sibling && sibling !== footer) {
            children.push(sibling);
            sibling = sibling.nextElementSibling;
          }

          const interval = Math.ceil(children.length / 4); // Divide by 4 for 25% interval marks
          children.forEach((child, index) => {
            if (index % interval === 0 || index === children.length - 1) {
              child.classList.add('progress-mark');
            }
          });
        }
      }
    }
  };

  return { trackReaderDepth, addReaderDepthMarkersBlog, addReaderDepthMarkersWww };
}

export default useTrackReaderDepth;